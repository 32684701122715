import React from 'react';

const tableTrubki = (data) => {
  // Проверяем, является ли data массивом
  if (!Array.isArray(data)) {
    // Если data не является массивом, возвращаем сообщение об ошибке
    return <p>Данные не являются массивом</p>;
  }

  // Получаем список всех возможных ключей для столбцов
  const columnKeysBefore = Object.keys(data[0]?.['До усадки'] || {});
  const columnKeysAfter = Object.keys(data[0]?.['Після усадки'] || {});

  // Определяем максимальное количество столбцов после усадки
  const maxColumnsAfter = Math.max(columnKeysAfter.length, ...data.map(item => Object.keys(item?.['Після усадки'] || {}).length));

  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th rowSpan="2">Стандартний розмір</th>
          <th colSpan={columnKeysBefore.length}>До усадки</th>
          <th colSpan={maxColumnsAfter}>Після усадки</th>
        </tr>
        <tr>
          {columnKeysBefore.map((key, index) => (
            <th key={`before-${index}`}>{key}</th>
          ))}
          {columnKeysAfter.map((key, index) => (
            <th key={`after-${index}`}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item['Стандартний розмір']}</td>
            {columnKeysBefore.map((key, index) => (
              <td key={`before-${index}`}>{item['До усадки'][key]}</td>
            ))}
            {columnKeysAfter.map((key, index) => (
              <td key={`after-${index}`}>{item['Після усадки'][key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default tableTrubki;
