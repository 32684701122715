import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import ContactPage from "./ContactPage";
import VideoPage from "./VideoPage";
import HeroList from "./HeroList";
import SearchBlock from "./SearchBlock";
import data from "./hero-item.json";
import "./App.css";
function App() {
  const videoIdsPlymouth = [
    'd-SrcO5ebKY',
    'zUItRM2xRYk',
    'dIxfdGSXEQ0'
  ];
  const videoIdsElcon = [
    'mvDhm2slAjI',
    'pOyX1fVN0CI',
    'y1u4liAtBn4',
    'UL9UJZImqLQ',
    '_d4r5Z_srQg'
  ];
  const allVideoIds = [ ...videoIdsElcon, ...videoIdsPlymouth];
  const [currentItem] = useState(null);
  const [selectedItemId] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openItems, setOpenItems] = useState([]);
  const [isPageCardOpen] = useState(false);
  const [isSearchBlockVisible, setIsSearchBlockVisible] = useState(false);

  const onToggle = () => {};

  const handleToggle = (itemId) => {
    onToggle(itemId);
    if (openItems.includes(itemId)) {
      setOpenItems(openItems.filter((id) => id !== itemId));
    } else {
      setOpenItems([...openItems, itemId]);
    }
  };

  const handleItemClick = (itemId) => {
    setSelectedItems([itemId]);

    if (!openItems.includes(itemId)) {
      setOpenItems([...openItems, itemId]);
    }
  };

  const handleOpenClick = () => {
    setSelectedItems(selectedItems); // Эта строка не делает ничего, нужно ли она здесь?
  };

  useEffect(() => {
    setIsSearchBlockVisible(false);
  }, [location.pathname]);

  const handleSearchButtonClick = () => {
    setIsSearchBlockVisible(true);
  };

  return (
    <Router >
      <div className="App">
        <header className="App-header">
          <div className="header-container">
          <a href="/" className="nav-text">
              <div className="logo-container">
              <img className="dneprosila-img" src={process.env.PUBLIC_URL + "/img/3572703401_w200_h100_tov-dniprosila.webp"} alt="img" />
              </div>
              </a>
            <ul className="list nav-list">
              <li className="nav-item">
                <a href="/" className="nav-text">
                  Головна
                </a>
              </li>
              <li className="nav-item">
                <Link to="/contacts" className="nav-text">
                  Контакти
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/video" className="nav-text">
                  Відеоматеріал
                </Link>
              </li>
            </ul>
          </div>
        </header>

        {/* Кнопка поиска */}
        <button
          className={`search-btn ${isSearchBlockVisible ? "hidden" : ""}`}
          onClick={handleSearchButtonClick}
        >
          <svg className="search-icon">
                    <use href="/img/sprite.svg#search-icon"></use>
                </svg>
           Швидкий пошук
        </button>
        {isSearchBlockVisible ? (
                  <SearchBlock
                    data={data}
                    onOpenClick={handleOpenClick}
                    isSearchBlockVisible={isSearchBlockVisible}
                    setIsSearchBlockVisible={setIsSearchBlockVisible}
                  />
                ) : null}
        <Routes>
          <Route path="contacts" element={<ContactPage />} />
          <Route path="video" element={<VideoPage videoIds={allVideoIds}/>} />
          <Route
            path="/*"
            element={
              <main>

                <div className="main-container">
                  {location.pathname === "/" && (
                    <section className="block-img">
                      <div className="hero-container">
                        <img className="main-img" src={process.env.PUBLIC_URL + "/img/main-img.webp"} alt="Main Image" />
                      </div>
                    </section>
                  )}
                  <HeroList
                    location={location}
                    currentItem={currentItem}
                    selectedItemId={selectedItemId}
                    onItemClick={handleItemClick}
                    isPageCardOpen={isPageCardOpen}
                    openItems={openItems}
                    onToggle={handleToggle}
                  />
                </div>
              </main>
            }
          />
        </Routes>

        <footer>
          <div className="footer-container">
            <div className="footer-prom-container">
              <a className="footer-link" target="blank" href="https://dneprosila.prom.ua/ua/">
                <img className="footer-prom" src={process.env.PUBLIC_URL + "/img/prom.ua.jpg"} alt="Prom.ua" />
              </a>
            </div>
            <ul className="contacts list">
              <li className="contacts-header__item">
                <a className="link" href="mailto:dneprosila@gmail.com">
                  dneprosila@gmail.com
                </a>
              </li>
              <li className="contacts-header__item">
                <ul className="footer-contacts-list list">
                  <li>
                    <a className="link" href="tel:+380685552545">
                      0 (68) 555 25 45
                    </a>
                  </li>
                  <li>
                    <a className="link" href="tel:+380685552535">
                      0 (68) 555 25 35
                    </a>
                  </li>
                  <li>
                    <a className="link" href="tel:+380965552545">
                      0 (96) 555 25 45
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
