import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";

function findItemById(itemId, dataArray) {
  for (const item of dataArray) {
    if (item.id === itemId) {
      return item;
    }
    if (item.items) {
      const nestedItem = findItemById(itemId, item.items);
      if (nestedItem) {
        return nestedItem;
      }
    }
  }
  return null;
}

const SearchBlock = ({ data, isSearchBlockVisible, setIsSearchBlockVisible }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [openLevel, setOpenLevel] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId;
    if (isSearchBlockVisible) {
      timeoutId = setTimeout(() => {
        const container = document.querySelector(".search-block-container");
        container.classList.add("visible");
      }, 50);
    }

    return () => clearTimeout(timeoutId);

  }, [isSearchBlockVisible]);

  const handleItemSelected = (itemId, level) => {
    const newSelectedItems = [...selectedItems.slice(0, level - 1), itemId];
    setSelectedItems(newSelectedItems);

    if (findItemById(itemId, data)?.items?.length > 0) {
      setOpenLevel(level + 1);
    }
  };

  const handleOpenButtonClick = () => {
    if (selectedItems.length > 0) {
      const lastSelectedItem = selectedItems[selectedItems.length - 1];
      navigate(`/item/${lastSelectedItem}`);
      setSelectedItems([]);
      setOpenLevel(1);
      setIsSearchBlockVisible(false);
    }
  };


  return (
    <div className="search-block-container">
      <ul className="search-block-list list">
        {Array.from({ length: openLevel }, (_, index) => {
          const items = index > 0 ? findItemById(selectedItems[index - 1], data)?.items || [] : data;

          if (items.length === 0) {
            return null;
          }

          return (
            <Dropdown
              key={index}
              level={index + 1}
              data={items}
              selectedItemId={selectedItems[index]}
              onItemSelected={(itemId) => {
                handleItemSelected(itemId, index + 1);
              }}
            />
          );
        })}
      </ul>
      <OpenButton onOpenButtonClick={() => {
        handleOpenButtonClick();
      }} />
    </div>
  );
};

function Dropdown({ data, selectedItemId, onItemSelected }) {
  // Добавим состояние для отслеживания видимости элемента
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Используем таймаут для добавления класса после рендеринга
    let timeoutId;
    if (isVisible) {
      timeoutId = setTimeout(() => {
        const items = document.querySelectorAll(".search-block-item");
        items.forEach((item) => item.classList.add("visible"));
      }, 10); // Небольшая задержка
    }

    return () => clearTimeout(timeoutId); // Очистка таймаута при размонтировании компонента

  }, [isVisible]);

  // Фильтрация данных
  const filteredData = data.filter(item => item.id !== "КАТАЛОГ Elcon-МУФТИ" && item.id !== "КАТАЛОГ Elcon-ТЕРМОЗБІЖНІ ВИРОБИ");

  return (
    <li className={`search-block-item ${isVisible ? 'visible' : ''}`}>
      <select
        className="search-block-select"
        value={selectedItemId || ""}
        onChange={(e) => {
          setIsVisible(true); // Установка видимости при изменении значения
          onItemSelected(e.target.value);
        }}
      >
        <option className="search-block-select-option">Оберіть Категорію</option>
        {filteredData.map((item) => (
          <option className="search-block-select-option" key={item.id} value={item.id}>
            {item.title}
          </option>
        ))}
      </select>
    </li>
  );
}

function OpenButton({ onOpenButtonClick }) {
  return (
    <div className="open-button-container">
      <button className="open-button" onClick={onOpenButtonClick}>
        Відкрити <span className="arrow">→</span>
      </button>
    </div>
  );
}

export default SearchBlock;