import React from 'react';

function customTable(data) {
    const { headers, subHeaders, data: tableData } = data;
  
    return (
      <table border="1">
        <tbody>
          <tr>
            <td colSpan="1" rowSpan="2">{headers[0]}</td>
            <td colSpan="1" rowSpan="2">{headers[1]}</td>
            <td colSpan="2" rowSpan="1">{headers[2]}</td>
            <td colSpan="1" rowSpan="2">{headers[3]}</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center' }}>{subHeaders[0]}</td>
            <td style={{ textAlign: 'center' }}>{subHeaders[1]}</td>
          </tr>
          {tableData.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              <td style={{ textAlign: 'center' }}>{rowData.size}</td>
              <td style={{ textAlign: 'center' }}>{rowData.article}</td>
              <td style={{ textAlign: 'center' }}>{rowData.minI}</td>
              <td style={{ textAlign: 'center' }}>{rowData.maxO}</td>
              <td style={{ textAlign: 'center' }}>{rowData.packaging}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  
export default customTable;