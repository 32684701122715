import React from 'react';

const WrapTable = ({ data }) => {
  if (!data || !Array.isArray(data)) {
    return null; // Можно также вернуть пустой элемент или сообщение об ошибке
  }
  const columnKeysBefore = Object.keys(data[0]?.['До усадки'] || {});
  const columnKeysAfter = Object.keys(data[0]?.['Після усадки'] || {});
  const maxColumnsAfter = Math.max(columnKeysAfter.length, ...data.map(item => Object.keys(item?.['Після усадки'] || {}).length));
  return (
    <table className="custom-table">
      <thead>
        <tr>
          <th rowSpan="2">Стандартний розмір</th>
          <th>Довжина</th>
          <th colSpan={columnKeysBefore.length}>До усадки</th>
          <th colSpan={maxColumnsAfter}>Після усадки</th>
        </tr>
        <tr>
          <th>L (mm)</th>
          <th>D (mm)</th>
          <th>Smin (mm)</th>
          <th>D (mm)</th>
          <th>Smin (mm)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item['Стандартний розмір']}</td>
            <td>{item['Довжина']}</td>
            <td>{item['До усадки']['D']}</td>
            <td>{item['До усадки']['Smin']}</td>
            <td>{item['Після усадки']['D']}</td>
            <td>{item['Після усадки']['Smin']}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default WrapTable;
