import React, { useState} from "react";
import SimpleMap from "./google-map.js";
import Modal from "./Modal";

function ContactPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div className="contacts-page">

      <h1>Контакти</h1>
      <div className="contacts-container">
        <div className="contacts-container_map">
          <SimpleMap />
        </div>
        <div className="contacts-container_text">
          <h2>ООО Днепросила</h2>
          <div>Телефони:
            <ul className="list">
            <li>
                    <a className="contact-page-link link" href="tel:+380685552545">
                      0 (68) 555 25 45
                    </a>
                  </li>
                  <li>
                    <a className="contact-page-link link" href="tel:+380685552535">
                      0 (68) 555 25 35
                    </a>
                  </li>
                  <li>
                    <a className="contact-page-link link" href="tel:+380965552545">
                      0 (96) 555 25 45
                    </a>
                  </li>
            </ul>
          </div>
          <div className="mail-container">Пошта:<a className="contact-page-link link" href="mailto:dneprosila@gmail.com">dneprosila@gmail.com</a>
          </div>
          <p>Адреса:</p>
          <p>Україна, м. Дніпро, вул. Сільська, буд. 72 , 49082 </p>
          <a
            href="/*"
            className="feedback-link"
            onClick={(event) => {
              event.preventDefault();
              setIsModalOpen(true);
            }}
          >
      <div className="feedback-container">
<div className="feedback-border">
<div className="feedback">
<div className="feedback-text-container">
<p className="feedback-text" >Залишити запит на зворотній зв`язок</p>
</div>
</div>
</div>
</div>
          </a>
        </div>
      </div>
      {isModalOpen && <Modal onClose={handleCloseModal} />}
    </div>
    
  );
}

export default ContactPage;