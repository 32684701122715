import React, { useState, useEffect } from "react";

const Modal = ({ onClose }) => {
  const [isFormOpen, setIsFormOpen] = useState(true); // состояние для первого модального окна
  const [isMessageOpen, setIsMessageOpen] = useState(false); // состояние для второго модального окна
  const [formData, setFormData] = useState({
    userName: "",
    userTel: "+38(0",
    userEmail: "",
    userText: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    userName: "",
    userTel: "",
    userEmail: "",
    userText: "",
  });

  useEffect(() => {
    if (formSubmitted) {
      const timer = setTimeout(() => {
        setIsFormOpen(false);
        setIsMessageOpen(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [formSubmitted]);

  useEffect(() => {
    if (errors.userText === "" && errors.userTel === "") {
      // Ошибка пустая, обновите компонент
    }
  }, [errors]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Валидация формы

    // Проверка всех обязательных полей
    if (!formData.userText.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        userText: "Це поле обов'язково до заповнення",
      }));
      return;
    }
    const phoneRegex = /^\+38 \(\d{3}\) \d{3} \d{2} \d{2} $/;
    if (!phoneRegex.test(formData.userTel)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        userTel: "Введено неправильний формат номеру",
      }));
      return;
    }
    try {
      const response = await fetch("send_email.php", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response);
      setIsFormOpen(false);
      setIsMessageOpen(true);
      setFormSubmitted(true);
      setErrors({
        userName: "",
        userTel: "",
        userEmail: "",
        userText: "",
      });
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
  
    if (name === "userTel") {
      // Форматирование номера телефона
  
      // Проверяем, если это событие удаления символов
      if (event.nativeEvent.inputType === 'deleteContentBackward') {
        // Если это нажатие клавиши Backspace
        const cursorPosition = event.target.selectionStart;
        const selectionEnd = event.target.selectionEnd;
        if (cursorPosition <= 4) {
          event.preventDefault();
          return;
        }
        // Удаляем символ, если курсор находится не в начале номера
        if (cursorPosition > 0) {
          const updatedValue = value.substring(0, cursorPosition - 1) + value.substring(selectionEnd);
          setFormData((prevData) => ({
            ...prevData,
            [name]: updatedValue,
          }));
        }
        return;
      }
  
      // Проверка на цифры
      if (!/^\d+$/.test(event.nativeEvent.data)) {
        event.preventDefault();
        return;
      }
  
      // Удаляем все нецифровые символы
      const phoneNumber = value.replace(/\D/g, "");
  
      // Ограничение длины
      if (phoneNumber.length > 13) {
        // Предотвращаем ввод
        event.preventDefault();
        return;
      }
  
      // Форматируем номер
      let formattedValue = "+38 ";
      if (phoneNumber.length > 2) {
        formattedValue += `(${phoneNumber.substring(2, 5)}) `;
      }
      if (phoneNumber.length > 5) {
        formattedValue += `${phoneNumber.substring(5, 8)} `;
      }
      if (phoneNumber.length > 8) {
        formattedValue += `${phoneNumber.substring(8, 10)} `;
      }
      if (phoneNumber.length > 10) {
        if (phoneNumber.length > 12) {
          // Ограничиваем количество символов до 12
          formattedValue += `${phoneNumber.substring(10, 12)} `;
        } else {
          // Добавляем 2 символа
          formattedValue += `${phoneNumber.substring(10, 12)} `;
        }
      }
  
      // Проверка наличия ошибок и скрытие сообщений об ошибках, если формат верный
      const phoneRegex = /^\+38 \(\d{3}\) \d{3} \d{2} \d{2} $/;
      if (phoneRegex.test(formattedValue)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        event.target.classList.remove('invalid'); // Удаляем класс invalid, если формат номера верный
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Введено неправильний формат номеру",
        }));
        event.target.classList.add('invalid'); // Добавляем класс invalid, если формат номера неверный
      }
  
      // Обновляем состояние formData
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedValue,
      }));
    } else if (name === "userText") {
      // Проверка на заполнение поля
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Це поле обов'язково до заповнення",
        }));
        event.target.classList.add('invalid'); // Добавляем класс invalid, если поле пустое
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        event.target.classList.remove('invalid'); // Удаляем класс invalid, если поле заполнено
      }
  
      // Обновляем состояние formData
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      // Если другое поле изменено, просто обновляем его значение
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  
  
  

  // Обработка удаления символов из инпута
  const handleKeyDown = (event) => {
    const { name, selectionStart } = event.target;
    const isBackspace = event.keyCode === 8;
  
    // Если это инпут для номера телефона и нажата клавиша Backspace
    if (name === "userTel" && isBackspace) {
      // Если курсор находится перед скобками или пробелами, перемещаем его на предыдущий символ
      if (selectionStart === 6 || selectionStart === 10 || selectionStart === 13) {
        event.target.selectionStart = event.target.selectionEnd = selectionStart - 2;
      }
    }
  };

  const handleClose = () => {
    setIsFormOpen(false);
    onClose();
  };

  return (
    <>
      {isFormOpen && (
        <div className="modal">
          <img className="dneprosila-img-modal" src={process.env.PUBLIC_URL + "/img/3572703401_w200_h100_tov-dniprosila.webp"} alt="img" />
          <div className="modal-content">
            <button className="close-btn" onClick={handleClose}>
              <svg className="svg-modal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <defs>
                  <style>
                    {`.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}`}
                  </style>
                </defs>
                <g id="cross">
                  <line className="cls-1" x1="7" x2="25" y1="7" y2="25" />
                  <line className="cls-1" x1="7" x2="25" y1="25" y2="7" />
                </g>
              </svg>
            </button>
            <form className="modal-form" onSubmit={handleSubmit}>
              <div className="form-field">
                <label htmlFor="user-name" className="form-label"></label>
                <span className="input-wrapper">
                  <input
                    className={`input-name form-input ${errors.userName ? 'invalid' : ''}`}
                    placeholder="Ім'я"
                    type="text"
                    name="userName"
                    id="user-name"
                    value={formData.userName}
                    onChange={handleChange}
                  />
                  {errors.userName && <p className="error-message">{errors.userName}</p>}
                </span>
              </div>
              <div className="form-field">
                <label htmlFor="user-tel" className="form-label"></label>
                <span className="input-wrapper">
                  <input
                    className={`input-name form-input ${errors.userTel ? 'invalid' : ''}`}
                    placeholder="Телефон"
                    type="tel"
                    name="userTel"
                    id="user-tel"
                    value={formData.userTel}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  {errors.userTel && <p className="error-message">{errors.userTel}</p>}
                </span>
              </div>
              <div className="form-field">
                <label htmlFor="user-email" className="form-label"></label>
                <span className="input-wrapper">
                  <input
                    className="input-name form-input"
                    placeholder="Пошта"
                    type="email"
                    name="userEmail"
                    id="user-email"
                    value={formData.userEmail}
                    onChange={handleChange}
                  />
                </span>
              </div>
              <div className="form-field">
                <label htmlFor="user-text" className="form-label"></label>
                <textarea
                  name="userText"
                  id="user-text"
                  className="modal-text-input"
                  placeholder="Введіть текст"
                  value={formData.userText}
                  onChange={handleChange}
                ></textarea>
                {errors.userText && <p className="error-message">{errors.userText}</p>}
              </div>
              <button type="submit" className="btn input-btn">
                Відправити
              </button>
            </form>
          </div>
        </div>
      )}
      {isMessageOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Дякуємо за надані дані, наш менеджер зв`яжеться з вами найближчим часом!</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
