import React, {useState,useEffect } from "react";
import { Routes, Route, Link, useParams} from "react-router-dom";
import data from "./hero-item.json";
import { ScaleLoader } from "react-spinners";
import ItemDescription from "./ItemDescription"


function HeroItem({ item, onItemClick, isItemOpen, selectedItemId }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  // Функция для загрузки изображения
  const loadImage = async () => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = `${process.env.PUBLIC_URL}/${item.img}`;
      image.onload = () => {
        setTimeout(() => {
          setImageLoaded(true);
          resolve();
        }, 900);
      };
    });
  };

  // Загрузка изображения после монтирования компонента
  useEffect(() => {
    const loadImagesSequentially = async () => {
      await loadImage();
    };

    loadImagesSequentially();
  }, [item.img]);

  // Обработчик клика по элементу
  const handleClick = () => {
    onItemClick(item.id);
  };

  // Стили для загрузки изображения
  const imgContainerStyle = {
    height: item.height,
    position: "relative",
  };

  const loadingSpinnerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <li
      style={{
        width: item.width,
        // Дополнительные стили здесь
      }}
      className={`hero-item ${isItemOpen ? "open" : ""} ${
        selectedItemId === item.id ? "selected" : ""
      } ${item.category === "Elcon Megarad" || item.category === "Муфти кабельні Elcon Megarad" || item.category === "Термоусаджувані вироби Elcon Megarad" || item.category === "Компоненти для монтажу Elcon Megarad" ? "categoryElcon" : ""} ${item.category === "Plumouth" ? "Plymouth" : ""} ${item.category === "Cembre" ? "Cembre" : ""}${item.category === "related" ? "related" : ""}`}
      key={item.id}
    >
      {item.object === "pdf" ? (
        // Если элемент является PDF, создаем ссылку
        <a
          href={`${process.env.PUBLIC_URL}/${item["card-pdf"]}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {item.title !== "Супутні товари" && (
            <div style={imgContainerStyle} className="superDIV">
              {imageLoaded ? (
                <img
                  loading="lazy"
                  style={{ height: item.height }}
                  className="hero__image"
                  alt="img"
                  src={`${process.env.PUBLIC_URL}/${item.img}`}
                />
              ) : (
                <div style={loadingSpinnerStyle}>
                  <ScaleLoader color={"#36D7B7"} size={100} />
                </div>
              )}
            </div>
          )}
          <div className="hero-item__text-container">
            <h3 className="hero__title">{item.title}</h3>
            <p>{item.preTitle}</p>
          </div>
        </a>
      ) : (
        // Если элемент не PDF, создаем ссылку React Router
        <Link
          className="hero-item_link"
          to={`/${item.category}/${item.id}`} // Включить категорию в ссылку
          onClick={handleClick}
        >
          {item.title !== "Супутні товари"&& item.title !== "КАТАЛОГ Elcon-МУФТИ" && item.title !== "КАТАЛОГ Elcon Megarad ТЕРМОЗБІЖНІ ВИРОБИ" &&(
            <div style={imgContainerStyle} className="superDIV">
              {imageLoaded ? (
                <img
                  loading="lazy"
                  style={{ height: item.height }}
                  className="hero__image"
                  alt="img"
                  src={`${process.env.PUBLIC_URL}/${item.img}`}
                />
              ) : (
                <div style={loadingSpinnerStyle}>
                  <ScaleLoader color={"#36D7B7"} size={100} />
                </div>
              )}
            </div>
          )}
          <div className="hero-item__text-container">
            <h3 className="hero__title">{item.title}</h3>
            {item.preTitle && <p>{item.preTitle}</p>}
          </div>
        </Link>
      )}
    </li>
  );
}


function findItemById(itemId, dataArray, category = "") {
  for (const item of dataArray) {
    if (item.id === itemId && (category === "" || item.category === category)) {
      return item;
    }
 
    if (item.items) {
      const nestedItem = findItemById(itemId, item.items, category);
      if (nestedItem) {
        return nestedItem;
      }
    }
  }
 
  return null;
 }



 function InnerList({ items }) {
  const hasElconMegaradCategory = items.some(item => item.category === "Муфти кабельні Elcon Megarad");
  const hasElconMegaradTrubkiCategory = items.some(item => item.category === "Термоусаджувані вироби Elcon Megarad");
  const pdfItemMufti = {
    id: "КАТАЛОГ Elcon-МУФТИ",
    title: "КАТАЛОГ Elcon-МУФТИ",
    category:"Elcon Megarad",
    width: "970px"
  };
  const pdfItemTrubki = {
    id: "КАТАЛОГ Elcon-ТЕРМОЗБІЖНІ ВИРОБИ",
    title: "КАТАЛОГ Elcon Megarad ТЕРМОЗБІЖНІ ВИРОБИ",
    category:"Elcon Megarad",
    width: "970px"
  };

  return (
    <div className="inner-list">
      <ul className="list-horizontal list">
        {items.map((item) => (
          <React.Fragment key={item.id}>
            {item.category === "Муфти кабельні Elcon Megarad" && (
              <HeroItem key={item.id} item={item} isItemOpen={false} onItemClick={() => {}} />
            )}
            {item.category !== "Муфти кабельні Elcon Megarad" && (
              <HeroItem key={item.id} item={item} isItemOpen={false} onItemClick={() => {}} />
            )}
          </React.Fragment>
        ))}
        {hasElconMegaradCategory && (
          <HeroItem key={pdfItemMufti.id} item={pdfItemMufti} isItemOpen={false} onItemClick={() => {}} />
        )}
        {hasElconMegaradTrubkiCategory && (
          <HeroItem key={pdfItemTrubki.id} item={pdfItemTrubki} isItemOpen={false} onItemClick={() => {}} />
        )}
      </ul>
    </div>
  );
}

function HomePage({ currentItem, onItemClick, isPageCardOpen, selectedItemId, onToggle }) {
  // Фильтруем данные, исключая элемент с id "КАТАЛОГ Elcon-МУФТИ"
  const filteredData = data.filter(item => item.id !== "КАТАЛОГ Elcon-МУФТИ" && item.id !== "КАТАЛОГ Elcon-ТЕРМОЗБІЖНІ ВИРОБИ")
  return (
    <>
      <ul className="list-horizontal list">
        {filteredData.map((el) => el.title && (
          <HeroItem
            key={el.id}
            item={el}
            onItemClick={onItemClick}
            isItemOpen={isPageCardOpen}
            isSelected={el.id === selectedItemId}
            onToggle={onToggle}
          />
        ))}
      </ul>
      {currentItem && isPageCardOpen && <ItemDescription item={currentItem} />}
    </>
  );
}

function ItemPage() {
  const { itemId } = useParams();
  const item = findItemById(itemId, data);

  if (!item) {
    return <div>Товар не найден</div>;
  }

  return (
    <>
      {item.items ? (
        item.items.length > 0 ? (
          <InnerList items={item.items} />
        ) : (
          <div className="alertPage">
            <h3>Товарів ще немає, але ми працюємо над цим</h3>
          </div>
        )
      ) : (
        <>
          <ItemDescription item={item} />
        </>
      )}
    </>
  );
}

function HeroList({ currentItem, onItemClick, isPageCardOpen, openItems, onToggle }) {
  return (
    <div className="hero-list_container">
      <Routes>
        <Route
          path="/"
          element={<HomePage currentItem={currentItem} onItemClick={onItemClick} isPageCardOpen={isPageCardOpen} openItems={openItems} onToggle={onToggle} />}
        />
        <Route
          path="/:category/:itemId"
          element={<ItemPage />}
        />
      </Routes>
    </div>
  );
}



export default HeroList;






