import React from 'react';

function renderTable(data) {
    const tableHeaders = data.map((table) => Object.values(table)[0]);
    const tableData = data.map((table) => table.nest.map((nestItem) => Object.values(nestItem)[0]));
  
    return (
      <table className="custom-table">
        <thead>
          <tr>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData[0].map((_, rowIndex) => (
            <tr key={rowIndex}>
              {tableData.map((rowData, columnIndex) => (
                <td  key={columnIndex}>{rowData[rowIndex]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  export default renderTable;














