import React from "react";
import YouTube from 'react-youtube'; // или import ReactPlayer from 'react-player';

const videoIdsPlymouth = [
  'd-SrcO5ebKY',
  'zUItRM2xRYk',
  'dIxfdGSXEQ0'
];
const videoIdsElcon = [
  'mvDhm2slAjI',
  'pOyX1fVN0CI',
  'y1u4liAtBn4',
  'UL9UJZImqLQ',
  '_d4r5Z_srQg'
];

const MiniPlayer = ({ videoId }) => {
  const opts = {
    height: '400',
    width: '680',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div>
      <YouTube videoId={videoId} opts={opts} />
    </div>
  );
};

function VideoPage({ videoIds }) {
  // Разделяем videoIds на два списка: Elcon Megarad и Plymouth
  const elconVideoIds = videoIds.filter(videoId => videoIdsElcon.includes(videoId));
  const plymouthVideoIds = videoIds.filter(videoId => videoIdsPlymouth.includes(videoId));

  return (
    <>
      <div>
        <h1>Elcon Megarad</h1>
        <div className="video-page">
          {elconVideoIds.map((videoId) => (
            <MiniPlayer key={videoId} videoId={videoId} />
          ))}
        </div>
      </div>
      <div>
        <h1>Plymouth</h1>
        <div className="video-page">
          {plymouthVideoIds.map((videoId) => (
            <MiniPlayer key={videoId} videoId={videoId} />
          ))}
        </div>
      </div>
    </>
  );
}

export default VideoPage;