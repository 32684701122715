import React from 'react';

const MicaniteTable = ({ data }) => {
  if (!Array.isArray(data)) {
    return null;
  }

  return (
    <table>
      <tbody>
        <tr>
          <th></th>
          <th>Од. виміру</th>
          <th>Мусковіт</th>
          <th>Флогопіт</th>
        </tr>
        {data.map((section, index) => (
          <React.Fragment key={index}>
            <tr>
              <th colSpan="4" className="th_micaniteTable">{section.section}</th>
            </tr>
            {section.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row["Тип"]}</td>
                <td dangerouslySetInnerHTML={{ __html: row["Од. виміру"] }} />
                <td>{row["Мусковіт"]}</td>
                <td>{row["Флогопіт"]}</td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default MicaniteTable;
