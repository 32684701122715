import React, { useState, useEffect } from 'react';
import customTable from "./customTable";
import renderTable from "./table";
import MicaniteTable from './micaniteTable';
import tableTrubki from './table_trubki'
import WrapTable from './wrapTable'
function ItemDescription({ item }) {
  const [activeTab, setActiveTab] = useState(Array.isArray(item) && item.length > 0 ? item[0] : 'pdf-btn');

  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomToggle = () => {
    setIsZoomed(!isZoomed);
  };


  useEffect(() => {
    if (item["card-Text"] && item["card-Text1"]) {
      setActiveTab('description-btn');
    } else if (item["card-Text1"]) {
      setActiveTab('description-btn');
    } else if (item["card-Text"]) {
      setActiveTab('application-btn');
    }
  }, []);
  

  const handleTabClick = (tab) => {
    console.log("Clicked tab:", tab);
    setActiveTab(tab);
  };

  const renderElconMegarad = () => {
    if (
      item["Виробник"] ||
      item["Країна виробник"] ||
      item["Тип елемента кріплення кабелю"] ||
      item["Довжина"] ||
      item["Довжина в упаковці"] ||
      item["Мінімальна температура усадки"] ||
      item["Максимальна температура усадки"] ||
      item["Мінімальна робоча температура"] ||
      item["Максимальна робоча температура"] ||
      item["Гарантійний термін"] ||
      item["Термін служби"] ||
      item["Тип труби"] ||
      item["Форма перерізу"] ||
      item["Спосіб монтажу"] ||
      item["Кількість отворів для кріплення"] ||
      item["Конструкція"] ||
      item["Тип накінечника"] ||
      item["Одноразове з'єднання"] ||
      item["Кількість в упаковці"] ||
      item["Стан"] ||
      item["Матеріал наконечника"] ||
      item["Максимальна напруга"] ||
      item["Переріз проводів, що підключаються кв. мм."]
    ) {
      return (
        <div className="item-info">
          <table className="item-table">
            <tbody>
              {Object.entries(item).map(([key, value]) => {
                if (
                  key === "Виробник" ||
                  key === "Країна виробник" ||
                  key === "Тип елемента кріплення кабелю" ||
                  key === "Довжина" ||
                  key === "Довжина в упаковці" ||
                  key === "Мінімальна температура усадки" ||
                  key === "Максимальна температура усадки" ||
                  key === "Мінімальна робоча температура" ||
                  key === "Максимальна робоча температура" ||
                  key === "Гарантійний термін" ||
                  key === "Термін служби" ||
                  key === "Тип труби" ||
                  key === "Форма перерізу" ||
                  key === "Спосіб монтажу" ||
                  key === "Кількість отворів для кріплення" ||
                  key === "Конструкція" ||
                  key === "Тип накінечника" ||
                  key === "Одноразове з'єднання" ||
                  key === "Кількість в упаковці" ||
                  key === "Стан" ||
                  key === "Матеріал наконечника" ||
                  key === "Максимальна напруга" ||
                  key === "Переріз проводів, що підключаються кв. мм."
                ) {
                  return (
                    <tr key={key} className="item-row">
                      <td className="item-property">{key}:</td>
                      <td className="item-value">{value}</td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };


  

  const renderListPlymouth = () => {
    if (item["card-list-plymouth"] && item["card-list-plymouth"].length > 0) {
      return (
        <div className="plymouth-list-container">
          <table className="plymouth-list">
            <tbody>
              {item["card-list-plymouth"].map((plymouthItem, index) => (
                <tr key={index} className="plymouth-list__item">
                  <td>{plymouthItem["list-items-plymouth"]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  }
  
  const renderSection4 = () => {
    if (item["cardCharacteristics"] && item["cardCharacteristics"].length > 0) {
      return (
        <div className="characteristics-list">
          <ul className='list'>
            {item["cardCharacteristics"].map((characteristic, index) => (
              <li key={index} className="item-info_text">{characteristic["cardCharacteristics"]}</li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  }



  
  if (item.items && item.items.length === 0) {
    return (
      <div className="page-card">
        <h3 className="hero__title_page-card">Товарів не знайдено</h3>
      </div>
    );
  }
  if (item.id && item.id === "Захисний поліестерний рукав") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
      <h3 className="hero__title_page-card">{item.title}</h3>
    
      <div className="itemDesc-button-container">
        <ul className='itemDesc-button-list list'>
        {item["card-Text1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('description-btn')}>Опис</li>}
  {item["card-Text"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
  {renderSection4() && <li className = "itemDesc-btn" onClick={() => handleTabClick('characteristics-btn')}>Характеристики</li>}
  {customTable(item["card-table"]) && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Таблиця Властивостей</li>}
  </ul>
</div>

{activeTab === 'description-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text1"] && <p className="hero-text1">{item["card-Text1"]}</p>}
        </div>
      )}
      {activeTab === 'application-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p>}
        </div>
      )}

{activeTab === 'list-property-btn' && (
        <div className='itemDescContainer'>
          {customTable(item["card-table"])}
        </div>
      )}
      {activeTab === 'characteristics-btn' && (
        <div className='itemDescContainer'>
          {renderSection4()}
        </div>
      )}
    </div>
    );
  }
  if (item.id && item.id === "Пластина з міканіту" || item.id === "Трубки (мусковіт)" || item.id === "Шайби з міканіту") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
  <img
    onClick={handleZoomToggle}
    loading="lazy"
    className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
    alt="img"
    src={`${process.env.PUBLIC_URL}/${item.img}`}
  />
      <h3 className="hero__title_page-card">{item.title}</h3>
    
      <div className="itemDesc-button-container">
        <ul className='itemDesc-button-list list'>
  {item["card-Text"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('Heat-resistant-micanite-btn')}>Термостійкий міканіт</li>}
  {item["card-Text1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('size-btn')}>Розміри</li>}
  {item["card-Text2"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('Interesting-Facts-btn')}>Цікаві факти</li>}
  {item["card-Text3"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
  {item["card-Text4"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('brands-btn')}>Марки</li>}
  {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-strength-btn')}>Таблиця міцності</li>}
  {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Таблиця Властивостей</li>}
  </ul>
</div>

      {activeTab === 'Heat-resistant-micanite-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p>}
        </div>
      )}

{activeTab === 'size-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text1"] && <p className="hero-text1">{item["card-Text1"]}</p>}
        </div>
      )}

{activeTab === 'Interesting-Facts-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text2"] && <p className="hero-text">{item["card-Text2"]}</p>}
        </div>
      )}
      {activeTab === 'application-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text3"] && <p className="hero-text">{item["card-Text3"]}</p>}
        </div>
      )}
            {activeTab === 'brands-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text4"] && <p className="hero-text">{item["card-Text4"]}</p>}
        </div>
      )}

{activeTab === 'list-strength-btn' && (
        <div className='itemDescContainer-table'>
          {item["card-table"] && renderTable(item["card-table"])}
        </div>
      )}

{activeTab === 'list-property-btn' && (
  <div className='itemDescContainer'>
    <MicaniteTable data={item["card-table1"]} />
  </div>
)}
    </div>
    );
  }
  if (item.id && item.id === "AST Антитрекінгова трубка з клеєм контролю напруги") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
        <div className='page-card-img'>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
          <h3 className="hero__title_page-card">{item.title}</h3>
          </div>
          <div className='super-container'>
          <div className="itemDesc-button-container">
            <ul className='itemDesc-button-list list'>
            {item["card-Text1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('description-btn')}>Опис</li>}
      {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
      {renderElconMegarad() && <li className = "itemDesc-btn" onClick={() => handleTabClick('Elcon-Megarad-characteristics-btn')}>Характеристики</li>}
      {renderListPlymouth() && <li className = "itemDesc-btn" onClick={() => handleTabClick('plymouth-characteristics-btn')}>Характеристики</li>}
      {renderSection4() && <li className = "itemDesc-btn" onClick={() => handleTabClick('characteristics-btn')}>Характеристики</li>}
      {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>Таблиця розмірів</li>}
      {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Трубка</li>}
      {item["card-table2"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn2')}>Клей</li>}
      {item["card-pdf"] && item["cardimgText"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('pdf-btn')}>PDF файл</li>}
      </ul>
    </div>
    
    
    {activeTab === 'application-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
              {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
            </div>
          )}
    
    {activeTab === 'description-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text1"] && <p className="hero-text1">{item["card-Text1"]}</p>}
            </div>
          )}
    
    
    
          {activeTab === 'list-size-btn' && (
            <div className='itemDescContainer-table'>
              {item["cardimg"] && (
                    <img
                      style={{ width: item.widthimg, height: item.heightimg}}
                      loading="lazy"
                      className="card-image"
                      alt="img"
                      src={`${process.env.PUBLIC_URL}/${item.cardimg}`}
                    />
                  )}
                  {item["cardimgText"] && (
                    <p>{item.cardimgText}</p>
                  )}
              {item["card-table"] && renderTable(item["card-table"])}
              {item["card-table-1"] && renderTable(item["card-table-1"])}
            </div>
          )}
    
    {activeTab === 'pdf-btn' && (
      <div className='itemDescContainer'>
        {item["card-pdf"] && (
          <embed
            src={`${process.env.PUBLIC_URL}/${item["card-pdf"]}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        )}
      </div>
    )}
    {activeTab === 'list-property-btn' && (
        <div className='itemDescContainer-table'>
          {item["card-table1"] && renderTable(item["card-table1"])}
        </div>
      )}
{activeTab === 'list-property-btn2' && (
        <div className='itemDescContainer-table'>
          {item["card-table2"] && renderTable(item["card-table2"])}
        </div>
      )}
    
          {activeTab === 'Elcon-Megarad-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderElconMegarad()}
            </div>
          )}
          {activeTab === 'plymouth-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderListPlymouth()}
            </div>
          )}
    
          {activeTab === 'characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderSection4()}
            </div>
          )}
    
    </div>
        </div>
      );
  }
  if (item.id && item.id === "SGIS Ізоляційно-напівпровідникові двостінні трубки зі збільшеною товщиною" || item.id === "GIS Ізоляційно-напівпровідні двостінні трубки") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
          <h3 className="hero__title_page-card">{item.title}</h3>
          <div className='super-container'>
          <div className="itemDesc-button-container">
            <ul className='itemDesc-button-list list'>
            {item["card-Text1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('description-btn')}>Опис</li>}
      {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
      {renderElconMegarad() && <li className = "itemDesc-btn" onClick={() => handleTabClick('Elcon-Megarad-characteristics-btn')}>Характеристики</li>}
      {renderListPlymouth() && <li className = "itemDesc-btn" onClick={() => handleTabClick('plymouth-characteristics-btn')}>Характеристики</li>}
      {renderSection4() && <li className = "itemDesc-btn" onClick={() => handleTabClick('characteristics-btn')}>Характеристики</li>}
      {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>Таблиця розмірів</li>}
      {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Ізоляційний шар</li>}
      {item["card-table2"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn2')}>Напівпровідний шар</li>}
      {item["card-pdf"] && item["cardimgText"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('pdf-btn')}>PDF файл</li>}
      </ul>
    </div>
    
    
    {activeTab === 'application-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
              {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
            </div>
          )}
    
    {activeTab === 'description-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text1"] && <p className="hero-text1">{item["card-Text1"]}</p>}
            </div>
          )}
    
    
    
          {activeTab === 'list-size-btn' && (
            <div className='itemDescContainer-table'>
              {item["cardimg"] && (
                    <img
                      style={{ width: item.widthimg, height: item.heightimg}}
                      loading="lazy"
                      className="card-image"
                      alt="img"
                      src={`${process.env.PUBLIC_URL}/${item.cardimg}`}
                    />
                  )}
                  {item["cardimgText"] && (
                    <p>{item.cardimgText}</p>
                  )}
              {item["card-table"] && renderTable(item["card-table"])}
              {item["card-table-1"] && renderTable(item["card-table-1"])}
            </div>
          )}
    
    
    {activeTab === 'pdf-btn' && (
      <div className='itemDescContainer'>
        {item["card-pdf"] && (
          <embed
            src={`${process.env.PUBLIC_URL}/${item["card-pdf"]}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        )}
      </div>
    )}
    {activeTab === 'list-property-btn' && (
        <div className='itemDescContainer-table'>
          {item["card-table1"] && renderTable(item["card-table1"])}
        </div>
      )}
{activeTab === 'list-property-btn2' && (
        <div className='itemDescContainer-table'>
          {item["card-table2"] && renderTable(item["card-table2"])}
        </div>
      )}
    
          {activeTab === 'Elcon-Megarad-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderElconMegarad()}
            </div>
          )}
          {activeTab === 'plymouth-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderListPlymouth()}
            </div>
          )}
    
          {activeTab === 'characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderSection4()}
            </div>
          )}
    
    </div>
        </div>
      );
  }
  if (item.id && item.id === "SIG Двостінна ізоляційна трубка з контролем напруги") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
          <h3 className="hero__title_page-card">{item.title}</h3>
          <div className='super-container'>
          <div className="itemDesc-button-container">
            <ul className='itemDesc-button-list list'>
            {item["card-Text1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('description-btn')}>Опис</li>}
      {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
      {renderElconMegarad() && <li className = "itemDesc-btn" onClick={() => handleTabClick('Elcon-Megarad-characteristics-btn')}>Характеристики</li>}
      {renderListPlymouth() && <li className = "itemDesc-btn" onClick={() => handleTabClick('plymouth-characteristics-btn')}>Характеристики</li>}
      {renderSection4() && <li className = "itemDesc-btn" onClick={() => handleTabClick('characteristics-btn')}>Характеристики</li>}
      {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>Таблиця розмірів</li>}
      {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Шар контролю напруги</li>}
      {item["card-table2"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn2')}>Ізоляційний шар</li>}
      {item["card-pdf"] && item["cardimgText"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('pdf-btn')}>PDF файл</li>}
      </ul>
    </div>
    
    
    {activeTab === 'application-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
              {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
            </div>
          )}
    
    {activeTab === 'description-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text1"] && <p className="hero-text1">{item["card-Text1"]}</p>}
            </div>
          )}
    
    
    
          {activeTab === 'list-size-btn' && (
            <div className='itemDescContainer-table'>
              {item["cardimg"] && (
                    <img
                      style={{ width: item.widthimg, height: item.heightimg}}
                      loading="lazy"
                      className="card-image"
                      alt="img"
                      src={`${process.env.PUBLIC_URL}/${item.cardimg}`}
                    />
                  )}
                  {item["cardimgText"] && (
                    <p>{item.cardimgText}</p>
                  )}
              {item["card-table"] && renderTable(item["card-table"])}
              {item["card-table-1"] && renderTable(item["card-table-1"])}
            </div>
          )}
    
    
    {activeTab === 'pdf-btn' && (
      <div className='itemDescContainer'>
        {item["card-pdf"] && (
          <embed
            src={`${process.env.PUBLIC_URL}/${item["card-pdf"]}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        )}
      </div>
    )}
    {activeTab === 'list-property-btn' && (
        <div className='itemDescContainer-table'>
          {item["card-table1"] && renderTable(item["card-table1"])}
        </div>
      )}
{activeTab === 'list-property-btn2' && (
        <div className='itemDescContainer-table'>
          {item["card-table2"] && renderTable(item["card-table2"])}
        </div>
      )}
    
          {activeTab === 'Elcon-Megarad-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderElconMegarad()}
            </div>
          )}
          {activeTab === 'plymouth-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderListPlymouth()}
            </div>
          )}
    
          {activeTab === 'characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderSection4()}
            </div>
          )}
    
    </div>
        </div>
      );
  }
  if (item.id && item.id === "TTS 11kV Трьохстінні контроль напруження - ізоляційно- напівпровідникові трубки") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
          <h3 className="hero__title_page-card">{item.title}</h3>
          <div className='super-container'>
          <div className="itemDesc-button-container">
            <ul className='itemDesc-button-list list'>
            {item["card-Text1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('description-btn')}>Опис</li>}
      {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
      {renderElconMegarad() && <li className = "itemDesc-btn" onClick={() => handleTabClick('Elcon-Megarad-characteristics-btn')}>Характеристики</li>}
      {renderListPlymouth() && <li className = "itemDesc-btn" onClick={() => handleTabClick('plymouth-characteristics-btn')}>Характеристики</li>}
      {renderSection4() && <li className = "itemDesc-btn" onClick={() => handleTabClick('characteristics-btn')}>Характеристики</li>}
      {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>Таблиця розмірів</li>}
      {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Шар контролю напруги</li>}
      {item["card-table2"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn2')}>Ізоляційний шар</li>}
      {item["card-table3"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn3')}>Напівпровідний шар</li>}
      {item["card-pdf"] && item["cardimgText"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('pdf-btn')}>PDF файл</li>}
      </ul>
    </div>
    
    
    {activeTab === 'application-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
              {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
            </div>
          )}
    
    {activeTab === 'description-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text1"] && <p className="hero-text1">{item["card-Text1"]}</p>}
            </div>
          )}
    
    
    
          {activeTab === 'list-size-btn' && (
            <div className='itemDescContainer-table'>
              {item["cardimg"] && (
                    <img
                      style={{ width: item.widthimg, height: item.heightimg}}
                      loading="lazy"
                      className="card-image"
                      alt="img"
                      src={`${process.env.PUBLIC_URL}/${item.cardimg}`}
                    />
                  )}
                  {item["cardimgText"] && (
                    <p>{item.cardimgText}</p>
                  )}
              {item["card-table"] && renderTable(item["card-table"])}
              {item["card-table-1"] && renderTable(item["card-table-1"])}
            </div>
          )}
    
    {activeTab === 'pdf-btn' && (
      <div className='itemDescContainer'>
        {item["card-pdf"] && (
          <embed
            src={`${process.env.PUBLIC_URL}/${item["card-pdf"]}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        )}
      </div>
    )}
    {activeTab === 'list-property-btn' && (
        <div className='itemDescContainer-table'>
          {item["card-table1"] && renderTable(item["card-table1"])}
        </div>
      )}
{activeTab === 'list-property-btn2' && (
        <div className='itemDescContainer-table'>
          {item["card-table2"] && renderTable(item["card-table2"])}
        </div>
      )}
      {activeTab === 'list-property-btn3' && (
        <div className='itemDescContainer-table'>
          {item["card-table3"] && renderTable(item["card-table3"])}
        </div>
      )}
    
          {activeTab === 'Elcon-Megarad-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderElconMegarad()}
            </div>
          )}
          {activeTab === 'plymouth-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderListPlymouth()}
            </div>
          )}
    
          {activeTab === 'characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderSection4()}
            </div>
          )}
    
    </div>
        </div>
      );
  }
  if (item.id && item.id === "ELCOTERM ICT Термозбіжний Ізолятор" || item.id === "ELCOTERM HSAB Термозбіжний кутовий кожух" || item.id === "ELCOTERM HSSB Термозбіжний прямий кожух" || item.id === "ELCOTERM TES Термозбіжні рукавички" || item.id === "ELCOTERM ATB Термозбіжна антитрекінгова рукавичка" || item.id === "ELCOCAP M10 Термозбіжна кабельна заглушка" ) {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
          <h3 className="hero__title_page-card">{item.title}</h3>
          <div className='super-container'>
          <div className="itemDesc-button-container">
            <ul className='itemDesc-button-list list'>
            {item["card-Text1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('description-btn')}>Опис</li>}
      {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
      {renderElconMegarad() && <li className = "itemDesc-btn" onClick={() => handleTabClick('Elcon-Megarad-characteristics-btn')}>Характеристики</li>}
      {renderListPlymouth() && <li className = "itemDesc-btn" onClick={() => handleTabClick('plymouth-characteristics-btn')}>Характеристики</li>}
      {renderSection4() && <li className = "itemDesc-btn" onClick={() => handleTabClick('characteristics-btn')}>Характеристики</li>}
      {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>Таблиця розмірів</li>}
      {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Таблиця властивостей</li>}
      {item["card-pdf"] && item["cardimgText"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('pdf-btn')}>PDF файл</li>}
      </ul>
    </div>
    
    
    {activeTab === 'application-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
              {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
            </div>
          )}
    
    {activeTab === 'description-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text1"] && <p className="hero-text1">{item["card-Text1"]}</p>}
            </div>
          )}
    
    
    
          {activeTab === 'list-size-btn' && (
            <div className='itemDescContainer-table'>
              {item["cardimg"] && (
                    <img
                      style={{ width: item.widthimg, height: item.heightimg}}
                      loading="lazy"
                      className="card-image"
                      alt="img"
                      src={`${process.env.PUBLIC_URL}/${item.cardimg}`}
                    />
                  )}
                  {item["cardimgText"] && (
                    <p>{item.cardimgText}</p>
                  )}
              {item["card-table"] && tableTrubki(item["card-table"])}
              {item["card-table-1"] && renderTable(item["card-table-1"])}
            </div>
          )}
    
    {activeTab === 'pdf-btn' && (
      <div className='itemDescContainer'>
        {item["card-pdf"] && (
          <embed
            src={`${process.env.PUBLIC_URL}/${item["card-pdf"]}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        )}
      </div>
    )}
    {activeTab === 'list-property-btn' && (
        <div className='itemDescContainer-table'>
          {item["card-table1"] && renderTable(item["card-table1"])}
        </div>
      )}
{activeTab === 'list-property-btn2' && (
        <div className='itemDescContainer-table'>
          {item["card-table2"] && renderTable(item["card-table2"])}
        </div>
      )}
      {activeTab === 'list-property-btn3' && (
        <div className='itemDescContainer-table'>
          {item["card-table3"] && renderTable(item["card-table3"])}
        </div>
      )}
    
          {activeTab === 'Elcon-Megarad-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderElconMegarad()}
            </div>
          )}
          {activeTab === 'plymouth-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderListPlymouth()}
            </div>
          )}
    
          {activeTab === 'characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderSection4()}
            </div>
          )}
    
    </div>
        </div>
      );
  }
  if (item.id && item.id === "WRAP AROUND WAC Термозбіжна ремонтна манжета") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
          <h3 className="hero__title_page-card">{item.title}</h3>
          <div className='super-container'>
          <div className="itemDesc-button-container">
            <ul className='itemDesc-button-list list'>
            {item["card-Text1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('description-btn')}>Опис</li>}
      {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
      {renderElconMegarad() && <li className = "itemDesc-btn" onClick={() => handleTabClick('Elcon-Megarad-characteristics-btn')}>Характеристики</li>}
      {renderListPlymouth() && <li className = "itemDesc-btn" onClick={() => handleTabClick('plymouth-characteristics-btn')}>Характеристики</li>}
      {renderSection4() && <li className = "itemDesc-btn" onClick={() => handleTabClick('characteristics-btn')}>Характеристики</li>}
      {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>Таблиця розмірів</li>}
      {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Таблиця властивостей</li>}
      {item["card-pdf"] && item["cardimgText"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('pdf-btn')}>PDF файл</li>}
      </ul>
    </div>
    
    
    {activeTab === 'application-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
              {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
            </div>
          )}
    
    {activeTab === 'description-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text1"] && <p className="hero-text1">{item["card-Text1"]}</p>}
            </div>
          )}
    
    
    
          {activeTab === 'list-size-btn' && (
            <div className='itemDescContainer-table'>
              {item["cardimg"] && (
                    <img
                      style={{ width: item.widthimg, height: item.heightimg}}
                      loading="lazy"
                      className="card-image"
                      alt="img"
                      src={`${process.env.PUBLIC_URL}/${item.cardimg}`}
                    />
                  )}
                  {item["cardimgText"] && (
                    <p>{item.cardimgText}</p>
                  )}
              {item["card-table"] && (
    <WrapTable data={item["card-table"]} />
)}
              {item["card-table-1"] && renderTable(item["card-table-1"])}
            </div>
          )}
    
    {activeTab === 'pdf-btn' && (
      <div className='itemDescContainer'>
        {item["card-pdf"] && (
          <embed
            src={`${process.env.PUBLIC_URL}/${item["card-pdf"]}`}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        )}
      </div>
    )}
    {activeTab === 'list-property-btn' && (
        <div className='itemDescContainer-table'>
          {item["card-table1"] && renderTable(item["card-table1"])}
        </div>
      )}
{activeTab === 'list-property-btn2' && (
        <div className='itemDescContainer-table'>
          {item["card-table2"] && renderTable(item["card-table2"])}
        </div>
      )}
      {activeTab === 'list-property-btn3' && (
        <div className='itemDescContainer-table'>
          {item["card-table3"] && renderTable(item["card-table3"])}
        </div>
      )}
    
          {activeTab === 'Elcon-Megarad-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderElconMegarad()}
            </div>
          )}
          {activeTab === 'plymouth-characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderListPlymouth()}
            </div>
          )}
    
          {activeTab === 'characteristics-btn' && (
            <div className='itemDescContainer'>
              {renderSection4()}
            </div>
          )}
    
    </div>
        </div>
      );
  }
  if (item.id && item.id === "ELCOTERM TIS 50") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
          <h3 className="hero__title_page-card">{item.title}</h3>
          <div className='super-container'>
          <div className="itemDesc-button-container">
            <ul className='itemDesc-button-list list'>
      {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
      {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>TIS 50 – Внутрішньої установки</li>}
      {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>TES 50 – Зовнішньої установки</li>}
      </ul>
    </div>
    
    
    {activeTab === 'application-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
              {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
            </div>
          )}
      {activeTab === 'list-size-btn' && (
        <div className='itemDescContainer-table'>
                        {item["cardimg"] && (
                <img
                  style={{ width: item.widthimg, height: item.heightimg}}
                  loading="lazy"
                  className="card-image"
                  alt="img"
                  src={`${process.env.PUBLIC_URL}/${item.cardimg}`}
                />
              )}
              {item["cardimgText"] && (
                <p>{item.cardimgText}</p>
              )}
          {item["card-table"] && renderTable(item["card-table"])}
          {item["card-table-1"] && renderTable(item["card-table-1"])}
        </div>
      )}
    
    {activeTab === 'list-property-btn' && (
        <div className='itemDescContainer-table'>
          {item["card-table1"] && renderTable(item["card-table1"])}
        </div>
      )}
    </div>
        </div>
      );
  }
  if (item.id && item.id === "ELCOGEL Гелеве з'єднання для полімерних кабелів") {
    console.log("item['card-table']: ", item["card-table"]);
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
          <h3 className="hero__title_page-card">{item.title}</h3>
          <div className='super-container'>
          <div className="itemDesc-button-container">
            <ul className='itemDesc-button-list list'>
      {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
      {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>Таблиця розмірів</li>}
      </ul>
    </div>
    
    
    {activeTab === 'application-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
              {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
            </div>
          )}
{activeTab === 'list-size-btn' && (
  <div className='itemDescContainer-table'>
    <table className="custom-table">
      <thead>
        <tr>
          <th rowSpan="3">Напруга (kV)</th>
          <th colSpan="3">Розмір (мм²)</th>
          <th rowSpan="3">Код товару</th>
          <th rowSpan="3">Зовнішні розміри (мм x мм x мм)</th>
        </tr>
        <tr>
          <th rowSpan="2">Пряме з`єднання</th>
          <th colSpan="2">Відгалужене з`єднання</th>
        </tr>
        <tr>
          <th>Головний</th>
          <th>Відгалуження</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowSpan="7">0,6 / 1 / 1,2</td>
          <td>1x1,5÷1х50</td>
          <td>1x1,5÷1х50</td>
          <td>1x1,5÷1х35</td>
          <td rowSpan="7">ELCOGEL 50</td>
          <td>50x35x100</td>
        </tr>
        <tr>
          <td>1х10÷1х120</td>
          <td>1х6÷1х70</td>
          <td>1х5÷1х35</td>
          <td rowSpan="3">65x37x160</td>
        </tr>
        <tr>
          <td>2х1,5÷2х16</td>
          <td>2х1,5÷2х10</td>
          <td>2х1,5÷2х6</td>
        </tr>
        <tr>
          <td>4х1,5÷4х6</td>
          <td>4х1,5÷4х6</td>
          <td>4х1,5÷4х4</td>
        </tr>
        <tr>
          <td>1х35÷1х185</td>
          <td>1х25÷1х150</td>
          <td>1х10÷1х120</td>
          <td rowSpan="3">100x53x220</td>
        </tr>
        <tr>
          <td>2х16÷2х50</td>
          <td>2х10÷2х35</td>
          <td>2х1,5÷2х25</td>
        </tr>
        <tr>
          <td>4х6÷4х25</td>
          <td>4х6÷4х25</td>
          <td>4х1,5÷4х16</td>
        </tr>
      </tbody>
    </table>
  </div>
)}

    
    </div>
        </div>
      );
  }
  if (item.id && item.id === "ELCOTERM GLS 50 - GLS 70") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
          <h3 className="hero__title_page-card">{item.title}</h3>
          <div className='super-container'>
          <div className="itemDesc-button-container">
            <ul className='itemDesc-button-list list'>
      {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
      {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>Таблиця розмірів GLS 50</li>}
      {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Таблиця розмірів GLS 70</li>}
      </ul>
    </div>
    
    
    {activeTab === 'application-btn' && (
            <div className='itemDescContainer'>
              {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
              {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
            </div>
          )}
      {activeTab === 'list-size-btn' && (
        <div className='itemDescContainer-table'>
          {item["card-table"] && renderTable(item["card-table"])}
        </div>
      )}
    
    {activeTab === 'list-property-btn' && (
        <div className='itemDescContainer-table'>
          {item["card-table1"] && renderTable(item["card-table1"])}
        </div>
      )}
    </div>
        </div>
      );
  }
  if (item.id && item.id === "Середньостінні термозбіжні трубки MPS 10" || item.id === "Товстостінні термозбіжні трубки MPS 20" || item.id === "Антитрекінгова та вогнестійка трубка МАТ" || item.id === "GMT Електроізоляційні трубки" || item.id === "MSC Напівпровідникові трубки" || item.id === "MCC 36kV J Трубка Контролю Напруги для кабельних З'єднань"|| item.id === "MCC 36kV T Трубка Контролю Напруги для кабельних Виводів"|| item.id === "MCC 11kV Трубка Контролю Напруги до 11кВ"| item.id === "Перехідна муфта для з'єднань 3-х жильних маслозаповнених кабелів з трьома одножильними кабелями із зшитого поліетилену з дротяним екраном.") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.imgDesc}`}
      /> <h3 className="hero__title_page-card">{item.title}</h3>
      <div className='super-container'>
      <div className="itemDesc-button-container">
        <ul className='itemDesc-button-list list'>
        {item["card-Text1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('description-btn')}>Опис</li>}
  {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
  {renderElconMegarad() && <li className = "itemDesc-btn" onClick={() => handleTabClick('Elcon-Megarad-characteristics-btn')}>Характеристики</li>}
  {renderListPlymouth() && <li className = "itemDesc-btn" onClick={() => handleTabClick('plymouth-characteristics-btn')}>Характеристики</li>}
  {renderSection4() && <li className = "itemDesc-btn" onClick={() => handleTabClick('characteristics-btn')}>Характеристики</li>}
  {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>Таблиця розмірів</li>}
  {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Таблиця Властивостей</li>}
  {item["card-pdf"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('pdf-btn')}>PDF файл</li>}
  </ul>
</div>


{activeTab === 'application-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
          {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
        </div>
      )}

{activeTab === 'description-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text1"] && <p className="hero-text1">{item["card-Text1"]}</p>}
        </div>
      )}



      {activeTab === 'list-size-btn' && (
        <div className='itemDescContainer-table'>
                        {item["cardimg"] && (
                <img
                  style={{ width: item.widthimg, height: item.heightimg}}
                  loading="lazy"
                  className="card-image"
                  alt="img"
                  src={`${process.env.PUBLIC_URL}/${item.cardimg}`}
                />
              )}
              {item["cardimgText"] && (
                <p>{item.cardimgText}</p>
              )}
          {item["card-table"] && renderTable(item["card-table"])}
          {item["card-table-1"] && renderTable(item["card-table-1"])}
        </div>
      )}

{activeTab === 'list-property-btn' && (
        <div className='itemDescContainer'>
          {item["card-table1"] && renderTable(item["card-table1"])}
        </div>
      )}

{activeTab === 'pdf-btn' && (
  <div className='itemDescContainer'>
    {item["card-pdf"] && (
      <embed
        src={`${process.env.PUBLIC_URL}/${item["card-pdf"]}`}
        type="application/pdf"
        width="100%"
        height="500px"
      />
    )}
  </div>
)}


      {activeTab === 'Elcon-Megarad-characteristics-btn' && (
        <div className='itemDescContainer'>
          {renderElconMegarad()}
        </div>
      )}
      {activeTab === 'plymouth-characteristics-btn' && (
        <div className='itemDescContainer-plymouth'>
          {renderListPlymouth()}
        </div>
      )}

      {activeTab === 'characteristics-btn' && (
        <div className='itemDescContainer'>
          {renderSection4()}
        </div>
      )}

</div>
    </div>
      );
  }

  if (item.id && item.id === "КАТАЛОГ Elcon-МУФТИ" || item.id === "КАТАЛОГ Elcon-ТЕРМОЗБІЖНІ ВИРОБИ") {
    return (
      <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
  <div className='itemDescContainerPDF'>
      <embed
        src={`${process.env.PUBLIC_URL}/${item["cardPdf"]}`}
        type="application/pdf"
        width="100%"
        height="1000px !important"
      />
  </div>

        </div>
      );
  }
  
return (
  
  <div className={`page-card ${isZoomed ? 'zoomed' : ''}`}>
        <div className='page-card-img'>
      <img
        onClick={handleZoomToggle}
        loading="lazy"
        className={`hero__image-page-card ${isZoomed ? 'zoomed-image' : ''}`}
        alt="img"
        src={`${process.env.PUBLIC_URL}/${item.img}`}
      />
          <h3 className="hero__title_page-card">{item.title}</h3>
          </div>
      <div className='super-container'>
      <div className="itemDesc-button-container">
        <ul className='itemDesc-button-list list'>
        {item["card-Text1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('description-btn')}>Опис</li>}
  {item["card-Text"]  &&<li className = "itemDesc-btn" onClick={() => handleTabClick('application-btn')}>Застосування</li>}
  {renderElconMegarad() && <li className = "itemDesc-btn" onClick={() => handleTabClick('Elcon-Megarad-characteristics-btn')}>Характеристики</li>}
  {renderListPlymouth() && <li className = "itemDesc-btn" onClick={() => handleTabClick('plymouth-characteristics-btn')}>Характеристики</li>}
  {renderSection4() && <li className = "itemDesc-btn" onClick={() => handleTabClick('characteristics-btn')}>Характеристики</li>}
  {item["card-table"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-size-btn')}>Таблиця розмірів</li>}
  {item["card-table1"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('list-property-btn')}>Таблиця Властивостей</li>}
  {item["card-pdf"] && <li className = "itemDesc-btn" onClick={() => handleTabClick('pdf-btn')}>PDF файл</li>}
  </ul>
</div>


{activeTab === 'application-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text"] && <p className="hero-text">{item["card-Text"]}</p> }
          {item["card-Title-Text"] && <h3 className='hero-text1-Title'>{item["card-Title-Text"]}</h3>}
        </div>
      )}

{activeTab === 'description-btn' && (
        <div className='itemDescContainer'>
          {item["card-Text1"] && <p className="hero-text1">{item["card-Text1"]}</p>}
        </div>
      )}



      {activeTab === 'list-size-btn' && (
        <div className='itemDescContainer-table'>
                        {item["cardimg"] && (
                <img
                  style={{ width: item.widthimg, height: item.heightimg}}
                  loading="lazy"
                  className="card-image"
                  alt="img"
                  src={`${process.env.PUBLIC_URL}/${item.cardimg}`}
                />
              )}
              {item["cardimgText"] && (
                <p>{item.cardimgText}</p>
              )}
          {item["card-table"] && renderTable(item["card-table"])}
          {item["card-table-1"] && renderTable(item["card-table-1"])}
        </div>
      )}

{activeTab === 'list-property-btn' && (
        <div className='itemDescContainer'>
          {item["card-table1"] && renderTable(item["card-table1"])}
        </div>
      )}

{activeTab === 'pdf-btn' && (
  <div className='itemDescContainer'>
    {item["card-pdf"] && (
      <embed
        src={`${process.env.PUBLIC_URL}/${item["card-pdf"]}`}
        type="application/pdf"
        width="100%"
        height="500px"
      />
    )}
  </div>
)}


      {activeTab === 'Elcon-Megarad-characteristics-btn' && (
        <div className='itemDescContainer'>
          {renderElconMegarad()}
        </div>
      )}
      {activeTab === 'plymouth-characteristics-btn' && (
        <div className='itemDescContainer-plymouth'>
          {renderListPlymouth()}
        </div>
      )}

      {activeTab === 'characteristics-btn' && (
        <div className='itemDescContainer'>
          {renderSection4()}
        </div>
      )}

</div>
    </div>
  );
}

export default ItemDescription;


